<template>
  <a-result title="未找到页面" sub-title="对不起，未找到该页面">
    <template #icon>
      <NotFoundSvg style="width: 200px; height: 300px" />
    </template>
    <template #extra>
      <a-button type="primary" @click="toHome"> 返回 {{ firstRouteName }} </a-button>
    </template>
  </a-result>
</template>

<script>
import { mapState } from 'vuex'
import NotFoundSvg from '@/assets/icons/404.svg?inline'
export default {
  components: { NotFoundSvg },
  name: 'Exception404',
  data () {
    return {
      firstRouteName: '',
      firstRoute: ''
    }
  },
  computed: {
    ...mapState({
      routers: (state) => state.permission.routers
    })
  },
  mounted () {
    this.firstRoute = this.routers.find((item) => item.name === 'index').children[0]
    this.firstRouteName = this.firstRoute.meta.title
  },
  methods: {
    toHome () {
      this.$router.push({ path: this.firstRoute.path })
    }
  }
}
</script>
